export const MODAL = {
  USER: 'modalUser',
  LOGIN: 'mobile-modal-login',
  DISPLAY_NAME: 'modalDisplayName',
  FORGOT_PASSWORD: 'modalForgotPassword',
  RESET_PASSWORD: 'modalResetPassword',
  DEPOSIT_WITHDRAW_GUIDE: 'modalDepositWithdrawGuide',
  UPDATE_DISPLAY_NAME: 'modalUpdateDisplayName',
  TRANSACTION_HISTORY_DETAIL: 'modalTransactionHistoryDetail',
  BETTING_HISTORY_DETAIL: 'modalBettingHistoryDetail',
  VERIFY_EMAIL: 'modalVerifyEmail',
  VERIFY_PHONE: 'modalVerifyPhone',
  DEPOSIT_QR_CODE: 'depositQrCode',
  BLOCK_GAME: 'modalBlockGame',
  CHECK_BALANCE: 'check-balance',
  FILTER_TIME: 'filterTime',
  FILTER_LEAGUE: 'filterLeague',
  CANCEL_DEPOSIT_CRYPTO: 'modal-cancel-deposit-crypto',
  QR_PREVIEW: 'modal-qr-preview',
  DETAIL_HISTORY: 'modal-detail-history',
  DETAIL_HISTORY_CARD: 'modal-detail-card',
  DETAIL_MATCH_SCHEDULE: 'modal-match-detail',
  ID_MODAL_SPECIAL: 'modal-special',
  ID_MODAL_PROMOTION_SLIDE: 'modal-promotion-slide',
  CANCEL_PROMOTION: 'modalCancelPromotion',
  MODAL_ADD_FIRST_BANK: 'modal-add-first-bank',
  MODAL_CONFIRM_CANCEL_SENPAY: 'modal-confirm-cancel-senpay',
  MODAL_FILTER_SELECTOR: 'filter-selector-modal'
}

export const KEY_EMIT = {
  CLOSE_MODAL: 'close-modal',
  CLOSE_MODAL_CANCEL_PROMOTION: 'close-modal-cancel-promotion',
  CANCEL_TRANSACTION: 'cancel-transaction',
  CANCEL_PROMOTION_COMFIRM: 'cancel-promotion-comfirm',
  CANCEL_PROMOTION: 'cancel-promotion',
  LINK_TO_DEPOSIT: 'link-to-deposit',
  LINK_TO_REWARD: 'link-to-reward',
  PLAY_GAME: 'play-game',
  LINK_TO_PROMOTION: 'link-to-promotion'
}

export const KEY_EMIT_INIT_MODAL_SPECIAL = 'init-modal-special'
export const KEY_EMIT_CLOSE_MODAL_SPECIAL = 'close-modal-special'
export const KEY_EMIT_INIT_MODAL_PROMOTION_SLIDE = 'init-modal-promotion'
export const KEY_EMIT_CLOSE_MODAL_PROMOTION_SLIDE = 'close-modal-promotion'

export const THEME_MODAL_SPECIAL = {
  BALANCE_CHECK: 'balance-check',
  DEPOSIT_CRYPTO: 'deposit-crypto',
  REWARD_WELCOME: 'reward-welcome',
  REWARD_SPECIAL: 'reward-special',
  NOTIFY_COMFIRM: 'notify-comfirm',
  PROMOTION_CANCEL: 'promotion-cancel',
  PROMOTION_COMFIRM: 'promotion-comfirm'
}

export const MODAL_CANCEL_PROMOTION = {
  type: 'MODAL_CANCEL_PROMOTION',
  id: MODAL.ID_MODAL_SPECIAL,
  title: 'BẠN ĐANG MUỐN HUỶ KHUYẾN MÃI',
  message: 'HUY HUY HUY',
  messageSub: '',
  theme: THEME_MODAL_SPECIAL.PROMOTION_CANCEL,
  textButton: 'Xác nhận hủy',
  emitActionButton: KEY_EMIT.CANCEL_PROMOTION,
  textLink: 'Không hủy',
  emitActionLink: KEY_EMIT.CLOSE_MODAL_CANCEL_PROMOTION
}
